import * as React from 'react';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from '../components/layout';
import Section from '../components/section';

const title = 'Data Security & Privacy';

const PrivacyPolicyPage = () => (
  <>
    <GatsbySeo
      title={title}
      description="Our data security and privacy statement "
      language="en"
      openGraph={{
        url: 'https://www.dsapps.dev/data-security-privacy/',
      }}
    />
    <Layout pageTitle={title}>
      <Section pageTitle={title}>
        <h2>Data security</h2>

        <p>We do not read your data.</p>

        <p>
          Our apps (with exceptions below) are served by static files, and as
          such, store no data. All markup is rendered client-side, so no data is
          transmitted to our servers.
        </p>

        <p>
          Any configuration data saved is hosted by your own{' '}
          <strong>Atlassian</strong> or <strong>monday.com</strong> instance and
          only accessed by client-side JavaScript.
        </p>

        <h3>Exceptions</h3>
        <p>
          The <strong>Google Analytics in Confluence</strong> and{' '}
          <strong>Google Analytics in Jira</strong> apps both use server-side
          technology and store some data.
        </p>
        <p>Details for these exceptions are linked below:</p>

        <ul>
          <li>
            <strong>Google Analytics in Confluence</strong>:{' '}
            <a
              target="_blank"
              href="https://help.dsapps.dev/google-analytics-in-confluence/what-data-is-stored-by-the-app-and-where"
            >
              What data is stored by the app and where?
            </a>
          </li>
          <li>
            <strong>Google Analytics in Jira</strong>:{' '}
            <a
              target="_blank"
              href="https://help.dsapps.dev/google-analytics-in-jira/what-data-is-stored-by-the-app-and-where"
            >
              What data is stored by the app and where?
            </a>
          </li>
        </ul>

        <h2>Privacy</h2>

        <p>We do not read your data.</p>

        <p>
          We do however reserve the right to track usage of our apps with web
          analytics software such as Google Analytics.
        </p>

        <h3>Google Analytics</h3>
        <p>
          We use Google Analytics so that we can track the usage of our apps.
        </p>
        <p>Items currently being tracked by Google Analytics:</p>

        <h4>For Atlassian apps</h4>

        <ul>
          <li>
            <strong>Atlassian</strong> instance domain e.g.{' '}
            <em>yourinstance.atlassian.net</em>
          </li>
          <li>Whether the user is logged in, or anonymous</li>
        </ul>
        <h4>For monday.com apps</h4>

        <ul>
          <li>
            <strong>monday.com</strong> instance domain e.g.{' '}
            <em>yourinstance.monday.com</em>
          </li>
        </ul>

        <h3>Embedding Third-Party Products</h3>

        <p>
          When our apps embed features from third-party products, the embedded
          feature will normally be subject to the privacy policy of the
          third-party vendor. Third-party vendors may also include analytics,
          monitoring and ad platforms trackers.
        </p>

        <h3>YouTube Videos</h3>
        <p>
          When demonstration or help videos are embedded in our products and
          this website, the YouTube videos will be subject to the privacy policy
          of YouTube, who may also include analytics, monitoring and ad
          platforms trackers.
        </p>

        {/* <p>
        Where possible we link to the vendor's website from the product page on
        this website so you can view their privacy policy.
      </p> */}
      </Section>
    </Layout>
  </>
);

export default PrivacyPolicyPage;
